var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Họ và tên"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Họ và tên","rules":"required","custom-messages":{required: "Họ và tên là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataGeneral.fullName),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "fullName", $$v)},expression:"dataGeneral.fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Ngày sinh"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Ngày sinh","rules":"required","custom-messages":{required: "Ngày sinh là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"state":errors.length > 0 ? false : null,"disabledInput":true,"disabled":_vm.disableProp},model:{value:(_vm.dataGeneral.birthDay),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "birthDay", $$v)},expression:"dataGeneral.birthDay"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Giới tính")]),_c('div',{staticClass:"d-flex mt-1"},[_c('b-form-radio',{staticClass:"mr-1",attrs:{"value":true},model:{value:(_vm.dataGeneral.gender),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "gender", $$v)},expression:"dataGeneral.gender"}},[_vm._v(" Nam ")]),_c('b-form-radio',{attrs:{"value":false},model:{value:(_vm.dataGeneral.gender),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "gender", $$v)},expression:"dataGeneral.gender"}},[_vm._v(" Nữ ")])],1)])],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Quốc tịch")]),_c('v-select',{attrs:{"reduce":function (item) { return item.id; },"label":"name","options":_vm.arrContry || [],"placeholder":"Lựa chọn Quốc tịch"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}]),model:{value:(_vm.dataGeneral.countryId),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "countryId", $$v)},expression:"dataGeneral.countryId"}})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Số hộ chiếu")]),_c('b-form-input',{model:{value:(_vm.dataGeneral.identityCardNumber),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "identityCardNumber", $$v)},expression:"dataGeneral.identityCardNumber"}})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Ngày cấp hộ chiếu"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Ngày cấp hộ chiếu","rules":"required","custom-messages":{required: "Ngày cấp hộ chiếu là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"state":errors.length > 0 ? false : null,"disabled":_vm.disableProp},model:{value:(_vm.dataGeneral.datessued),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "datessued", $$v)},expression:"dataGeneral.datessued"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Cơ quan cấp hộ chiếu")]),_c('b-form-input',{model:{value:(_vm.dataGeneral.locationIssued),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "locationIssued", $$v)},expression:"dataGeneral.locationIssued"}})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Có giá trị đến ngày"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Có giá trị đến ngày","rules":"required","custom-messages":{required: "Có giá trị đến ngày là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"state":errors.length > 0 ? false : null,"disabled":_vm.disableProp},model:{value:(_vm.dataGeneral.dateExpired),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "dateExpired", $$v)},expression:"dataGeneral.dateExpired"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Trình độ"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Trình độ","rules":"required","custom-messages":{required: "Trình độ là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (item) { return item.key; },"label":"name","options":_vm.foreignerEducationLevel || [],"placeholder":"Lựa chọn Trình độ"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataGeneral.foreignerEducationLevel),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "foreignerEducationLevel", $$v)},expression:"dataGeneral.foreignerEducationLevel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Chuyên môn đào tạo"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Chuyên môn đào tạo","rules":"required","custom-messages":{required: "Chuyên môn đào tạo là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (item) { return item.key; },"label":"name","options":_vm.foreignerQualification || [],"placeholder":"Lựa chọn Chuyên môn đào tạo"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataGeneral.foreignerQualification),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "foreignerQualification", $$v)},expression:"dataGeneral.foreignerQualification"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Ngày ghi sổ"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"dateCollect","rules":"required","custom-messages":{required: "Ngày ghi sổ là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"state":errors.length > 0 ? false : null,"disabled":_vm.disableProp},model:{value:(_vm.dataGeneral.dateCollect),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "dateCollect", $$v)},expression:"dataGeneral.dateCollect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"SquareIcon"}}),_c('span',[_vm._v(" Thẻ tạm trú/VISA ")])],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Thẻ tạm trú/VISA"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Thẻ tạm trú/VISA","rules":"required","custom-messages":{required: "Thẻ tạm trú/VISA là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (item) { return item.key; },"label":"name","options":_vm.foreignVISA || [],"placeholder":"Lựa chọn Thẻ tạm trú/VISA"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataGeneral.foreignVISA),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "foreignVISA", $$v)},expression:"dataGeneral.foreignVISA"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Số thẻ tạm trú/VISA")]),_c('b-form-input',{model:{value:(_vm.dataGeneral.codeForeignVISA),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "codeForeignVISA", $$v)},expression:"dataGeneral.codeForeignVISA"}})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Thời hạn thẻ tạm trú/VISA"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Thời hạn thẻ tạm trú/VISA","rules":"required","custom-messages":{required: "Thời hạn thẻ tạm trú/VISA là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"state":errors.length > 0 ? false : null,"disabled":_vm.disableProp},model:{value:(_vm.dataGeneral.dateExpiredVISA),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "dateExpiredVISA", $$v)},expression:"dataGeneral.dateExpiredVISA"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Ký hiệu thẻ tạm trú/VISA"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Ký hiệu thẻ tạm trú/VISA","rules":"required","custom-messages":{required: "Ký hiệu thẻ tạm trú/VISA là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (item) { return item.key; },"label":"name","options":_vm.foreignVISAType || [],"placeholder":"Lựa chọn Ký hiệu thẻ tạm trú/VISA"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataGeneral.foreignVISAType),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "foreignVISAType", $$v)},expression:"dataGeneral.foreignVISAType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"FileIcon"}}),_c('span',[_vm._v(" Giấy phép/Giấy xác nhận ")])],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Cấp giấy phép/Giấy xác nhận"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Cấp giấy phép/Giấy xác nhận","rules":"required","custom-messages":{required: "Cấp giấy phép/Giấy xác nhận là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (item) { return item.key; },"label":"name","options":_vm.workerLicenseType || [],"placeholder":"Lựa chọn Cấp giấy phép/Giấy xác nhận"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataGeneral.workerLicenseType),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "workerLicenseType", $$v)},expression:"dataGeneral.workerLicenseType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Ngày cấp giấy phép"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Ngày cấp giấy phép","rules":"required","custom-messages":{required: "Ngày cấp giấy phép là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"state":errors.length > 0 ? false : null,"disabled":_vm.disableProp},model:{value:(_vm.dataGeneral.dateLicense),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "dateLicense", $$v)},expression:"dataGeneral.dateLicense"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Ghi chú")]),_c('b-form-textarea',{attrs:{"rows":"3","no-resize":""},model:{value:(_vm.dataGeneral.workerLicenseNote),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "workerLicenseNote", $$v)},expression:"dataGeneral.workerLicenseNote"}})],1)],1)],1),_c('h3',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"FileIcon"}}),_c('span',[_vm._v(" Thu hồi giấy phép/Giấy xác nhận ")])],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Thu hồi giấy phép/Giấy xác nhận")]),_c('b-form-input',{model:{value:(_vm.dataGeneral.revokeLicense),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "revokeLicense", $$v)},expression:"dataGeneral.revokeLicense"}})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Ngày thu hồi"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Ngày thu hồi","rules":"required","custom-messages":{required: "Ngày thu hồi là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"state":errors.length > 0 ? false : null,"disabled":_vm.disableProp},model:{value:(_vm.dataGeneral.dateRevoke),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "dateRevoke", $$v)},expression:"dataGeneral.dateRevoke"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Ghi chú")]),_c('b-form-textarea',{attrs:{"rows":"3","no-resize":""},model:{value:(_vm.dataGeneral.noteRevoke),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "noteRevoke", $$v)},expression:"dataGeneral.noteRevoke"}})],1)],1)],1),_c('h3',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"FileIcon"}}),_c('span',[_vm._v(" Cấp/cấp lại/gia hạn/xác nhận không thuộc diện cấp GPLD ")])],1),_c('b-row',[_c('b-col',{attrs:{"xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',[_c('label'),_c('b-form-checkbox',{staticClass:"mt-2",model:{value:(_vm.dataGeneral.isRenew),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "isRenew", $$v)},expression:"dataGeneral.isRenew"}},[_vm._v(" Chưa được Cấp/ cấp lại/ gia hạn/xác nhận không thuộc diện cấp GPLD ")])],1)],1),_c('b-col',{attrs:{"xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Lý do")]),_c('b-form-textarea',{attrs:{"rows":"3","no-resize":""},model:{value:(_vm.dataGeneral.reason),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "reason", $$v)},expression:"dataGeneral.reason"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }