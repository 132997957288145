<template>
  <div>
    <b-form>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Họ và tên<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Họ và tên"
              rules="required"
              :custom-messages="{required: `Họ và tên là bắt buộc`}"
            >
              <b-form-input
                v-model="dataGeneral.fullName"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Ngày sinh<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Ngày sinh"
              rules="required"
              :custom-messages="{required: `Ngày sinh là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataGeneral.birthDay"
                :state="errors.length > 0 ? false : null"
                :disabledInput="true"
                :disabled="disableProp"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Giới tính</label>
            <div class="d-flex mt-1">
              <b-form-radio
                v-model="dataGeneral.gender"
                class="mr-1"
                :value="true"
              >
                Nam
              </b-form-radio>
              <b-form-radio
                v-model="dataGeneral.gender"
                :value="false"
              >
                Nữ
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Quốc tịch</label>
            <v-select
              v-model="dataGeneral.countryId"
              :reduce="item => item.id"
              label="name"
              :options="arrContry || []"
              placeholder="Lựa chọn Quốc tịch"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Số hộ chiếu</label>
            <b-form-input
              v-model="dataGeneral.identityCardNumber"
            />
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Ngày cấp hộ chiếu<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Ngày cấp hộ chiếu"
              rules="required"
              :custom-messages="{required: `Ngày cấp hộ chiếu là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataGeneral.datessued"
                :disabledInput="true"
                :state="errors.length > 0 ? false : null"
                :disabled="disableProp"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Cơ quan cấp hộ chiếu</label>
            <b-form-input
              v-model="dataGeneral.locationIssued"
            />
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Có giá trị đến ngày<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Có giá trị đến ngày"
              rules="required"
              :custom-messages="{required: `Có giá trị đến ngày là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataGeneral.dateExpired"
                :disabledInput="true"
                :state="errors.length > 0 ? false : null"
                :disabled="disableProp"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Trình độ<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Trình độ"
              rules="required"
              :custom-messages="{required: `Trình độ là bắt buộc`}"
            >
              <v-select
                v-model="dataGeneral.foreignerEducationLevel"
                :reduce="item => item.key"
                label="name"
                :class="{'is-invalid':errors.length > 0 }"
                :options="foreignerEducationLevel || []"
                placeholder="Lựa chọn Trình độ"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Chuyên môn đào tạo<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Chuyên môn đào tạo"
              rules="required"
              :custom-messages="{required: `Chuyên môn đào tạo là bắt buộc`}"
            >
              <v-select
                v-model="dataGeneral.foreignerQualification"
                :reduce="item => item.key"
                label="name"
                :class="{'is-invalid':errors.length > 0 }"
                :options="foreignerQualification || []"
                placeholder="Lựa chọn Chuyên môn đào tạo"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Ngày ghi sổ<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="dateCollect"
              rules="required"
              :custom-messages="{required: `Ngày ghi sổ là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataGeneral.dateCollect"
                :disabledInput="true"
                :state="errors.length > 0 ? false : null"
                :disabled="disableProp"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="d-flex align-items-center">
        <feather-icon
          size="20"
          icon="SquareIcon"
          class="mr-75"
        />
        <span>
          Thẻ tạm trú/VISA
        </span>
      </h3>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Thẻ tạm trú/VISA<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Thẻ tạm trú/VISA"
              rules="required"
              :custom-messages="{required: `Thẻ tạm trú/VISA là bắt buộc`}"
            >
              <v-select
                v-model="dataGeneral.foreignVISA"
                :reduce="item => item.key"
                :class="{'is-invalid':errors.length > 0 }"
                label="name"
                :options="foreignVISA || []"
                placeholder="Lựa chọn Thẻ tạm trú/VISA"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Số thẻ tạm trú/VISA</label>
            <b-form-input
              v-model="dataGeneral.codeForeignVISA"
            />
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Thời hạn thẻ tạm trú/VISA<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Thời hạn thẻ tạm trú/VISA"
              rules="required"
              :custom-messages="{required: `Thời hạn thẻ tạm trú/VISA là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataGeneral.dateExpiredVISA"
                :disabledInput="true"
                :state="errors.length > 0 ? false : null"
                :disabled="disableProp"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Ký hiệu thẻ tạm trú/VISA<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Ký hiệu thẻ tạm trú/VISA"
              rules="required"
              :custom-messages="{required: `Ký hiệu thẻ tạm trú/VISA là bắt buộc`}"
            >
              <v-select
                v-model="dataGeneral.foreignVISAType"
                :reduce="item => item.key"
                label="name"
                :class="{'is-invalid':errors.length > 0 }"
                :options="foreignVISAType || []"
                placeholder="Lựa chọn Ký hiệu thẻ tạm trú/VISA"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="d-flex align-items-center">
        <feather-icon
          size="20"
          icon="FileIcon"
          class="mr-75"
        />
        <span>
          Giấy phép/Giấy xác nhận
        </span>
      </h3>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Cấp giấy phép/Giấy xác nhận<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Cấp giấy phép/Giấy xác nhận"
              rules="required"
              :custom-messages="{required: `Cấp giấy phép/Giấy xác nhận là bắt buộc`}"
            >
              <v-select
                v-model="dataGeneral.workerLicenseType"
                :reduce="item => item.key"
                label="name"
                :class="{'is-invalid':errors.length > 0 }"
                :options="workerLicenseType || []"
                placeholder="Lựa chọn Cấp giấy phép/Giấy xác nhận"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Ngày cấp giấy phép<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Ngày cấp giấy phép"
              rules="required"
              :custom-messages="{required: `Ngày cấp giấy phép là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataGeneral.dateLicense"
                :disabledInput="true"
                :state="errors.length > 0 ? false : null"
                :disabled="disableProp"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="12"
          lg="12"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Ghi chú</label>
            <b-form-textarea
              v-model="dataGeneral.workerLicenseNote"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="d-flex align-items-center">
        <feather-icon
          size="20"
          icon="FileIcon"
          class="mr-75"
        />
        <span>
          Thu hồi giấy phép/Giấy xác nhận
        </span>
      </h3>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Thu hồi giấy phép/Giấy xác nhận</label>
            <b-form-input
              v-model="dataGeneral.revokeLicense"
            />
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Ngày thu hồi<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Ngày thu hồi"
              rules="required"
              :custom-messages="{required: `Ngày thu hồi là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataGeneral.dateRevoke"
                :disabledInput="true"
                :state="errors.length > 0 ? false : null"
                :disabled="disableProp"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="12"
          lg="12"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Ghi chú</label>
            <b-form-textarea
              v-model="dataGeneral.noteRevoke"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="d-flex align-items-center">
        <feather-icon
          size="20"
          icon="FileIcon"
          class="mr-75"
        />
        <span>
          Cấp/cấp lại/gia hạn/xác nhận không thuộc diện cấp GPLD
        </span>
      </h3>
      <b-row>
        <b-col
          xl="12"
          lg="12"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label></label>
            <b-form-checkbox
              v-model="dataGeneral.isRenew"
              class="mt-2"
            >
              Chưa được Cấp/ cấp lại/ gia hạn/xác nhận không thuộc diện cấp GPLD
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col
          xl="12"
          lg="12"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Lý do</label>
            <b-form-textarea
              v-model="dataGeneral.reason"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, numeric, email,
} from '@validations'
import vSelect from 'vue-select'
import enumSelect from '../EnumSelected'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    vSelect,
    DateTimePicker,
    BFormRadio,
    BFormCheckbox,
    BFormTextarea,
  },

  props: {
    dataGeneral: {
      type: Object,
      default: _ => {},
    },
    // type: {
    //   type: String,
    //   default: '',
    // },
  },

  data() {
    return {
      disableProp: false,
      foreignerQualification: enumSelect.foreignerQualification,
      foreignerEducationLevel: enumSelect.foreignerEducationLevel,
      scaleWorker: enumSelect.scaleWorker,
      foreignVISA: enumSelect.foreignVISA,
      foreignVISAType: enumSelect.foreignVISAType,
      workerLicenseType: enumSelect.workerLicenseType,
      arrContry: [],
    }
  },

  async created() {
    const fetchContry = await this.fecthCountry()
    this.arrContry = fetchContry.data
  },

  methods: {
    ...mapActions('worker', ['fecthCountry']),
  },
}
</script>

<style>

</style>
